@import url('https://fonts.googleapis.com/css2?family=Audiowide&family=Rubik:wght@500&display=swap');
.topStrip
{
    background-color: rgb(245, 245, 245);
    padding-top: 10px;
    padding-left: 10px;;
    padding-bottom: 10px;
    border-top: 4px solid rgb(231, 193, 20);
}

.fraCode
{
    font-family: 'Audiowide', cursive;
    font-size: 20px;;
}
.fraName
{
    font-family: 'Rubik', sans-serif;
    font-size: 17px;
}
