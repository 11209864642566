.examCard
{
    background-color: rgb(241, 241, 241);
    padding:10px;
    margin-bottom: 15px;
    border-radius: 3px;
    border-bottom: 3px solid rgb(241, 208, 16);
}

.button
{
    padding-top:4px;
    padding-bottom: 5px;
    background-color: green;
}
.button:hover
{
    background-color: rgb(2, 88, 2);
}
.duration
{
    font-weight: 600;
}
.code
{
    font-size: 16px;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 600;
}