.title {
    color: rgb(32, 32, 32);
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1.15px;
    display: inline-block;
    padding:8px 14px 6px 14px;
    font-weight: 500;
    
  }

  .wrapper
  {
    display: block;
  }