.main {
    position: relative;
    width: 100%;
    height: 420px;
    text-align: center;
  }
  .clock {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
    height: 200px;
    border: 20px solid #5C484E;
    border-radius: 50%;
  }
  .seconds {
    height: 40%;
    width: 1%;
    background: #5C484E;
    border: 5px solid #5C484E;
    border-radius: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform-origin: 10% 0%;
    animation: rotate-second linear 2s infinite;
  }
  .minutes {
    height: 35%;
    width: 1%;
    background: #5C484E;
    border: 6px solid #5C484E;
    border-radius: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform-origin: 10% 0%;
    animation: rotate-minutes linear 8s infinite;
  }
   .title {
    color: #4C4C44;
    font-size: 30px;
    font-family: Arial;
    margin: 20px auto;
  }
  @keyframes rotate-second {
    0% {
      transform: rotate(0deg) translate(-50%, -90%);
    }
    100% {
      transform: rotate(360deg) translate(-50%, -90%);
    }
  }
  @keyframes rotate-minutes {
    0% {
      transform: rotate(0deg) translate(-50%, -90%);
    }
    100% {
      transform: rotate(360deg) translate(-50%, -90%);
    }
  }
  