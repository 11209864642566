@import url('https://fonts.googleapis.com/css2?family=Sawarabi+Gothic&display=swap');

.timer {
    font-family: 'Sawarabi Gothic', sans-serif;
    font-weight: 900;
    font-size: 20px;
    text-align: end;

}


.timer::before {
    content: '🕑';
     margin-right: 5px;;
}

.timerHolder {
    border: 3px solid rgb(85, 85, 85);
    border: 3px solid rgb(85, 85, 85);
    display: inline-block;
    padding: 3px 5px 2px 5px;
    border-radius: 5px;
    background-color: #626262;
    color: white;
}

.container {
    margin-top: 40px;
    width: 850px;
    background-color: rgb(230, 230, 230);
    border: 1px solid rgb(224, 224, 224);
    padding: 25px;
    margin-top: 40px;
    width: 850px;
    background-color: rgb(249 249 249);
    border: 1px solid rgb(224, 224, 224);
    padding: 25px;
    border-radius: 5px;
    box-shadow: 1px 3px 13px 0px #dfdfdf;
}

.switch {
    margin-top: 7px;
}


.button {
    padding-top: 4px;
    padding-bottom: 5px;
    border-radius: 3px;
    background-color: green !important;
    border-color: green;
    margin-top: 25px;
    margin-right: 18px;
}

.button {
    padding-top: 4px;
    padding-bottom: 5px;
    border-radius: 3px;
    background-color: green;
    border-color: green;
    margin-top: 25px;
    margin-right: 18px;
}

.next {
    background-color: rgb(1, 64, 136) !important;
    border-color: rgb(30, 54, 192);
}

.prev { background-color: rgb(104, 104, 104) !important;
    border-color: rgb(138, 137, 137);}

.submit {}