.studentStrip
{
    background-color: rgb(3, 71, 40);
    padding-top: 12px;
    padding-left: 10px;;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-top:2px solid rgb(218, 218, 218);
    border-bottom:2px solid rgb(218, 218, 218);
    border-left:4px solid rgb(241, 198, 6);
    margin-top: 1px;

}
.strong{
    color:white;
}
.row
{
    margin-left: 0px;
    margin-right: 0px;
}

.logout {
    padding-top: 2px;
    padding-bottom: 3px;
    border-radius: 3px;
    background-color: green!important;
    border-color: green;
    margin-top: -4px;
    


}

.logout:hover {
    color: white;
    background-color: rgb(27, 109, 98)!important;
    border-color: green
}